/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useRef, useEffect } from 'react';

/**
 *
 * @param {boolean, string, number} value - used for hooks to get ref to previous prop. e.g. useSWPrefetchPDP.js
 */

const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export default usePrevious;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { useUIDSeed } from 'react-uid';
import {
    shape, object, arrayOf, string, number, func, bool, array,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router';

import noop from '../../../../helpers/noop';
import customBreakpoints from '../../../../helpers/customBreakpoints';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { trackProductListData } from '../../../../helpers/tracking/common/commonTrackingHelpers';
import { GraphqlSimpleProduct } from '../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/GraphqlSimpleProduct';
import { emitProductImpression } from '../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { getFeatureFlag, getFeatureFlags, getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import LoadMoreButton from '../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/LoadMoreButton';
import usePrevious from '../../../../gql/hooks/usePrevious';

const styles = (theme) => ({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    simpleProduct3row: {
        flex: '0 0 33.33%',
        marginTop: '8px',
        marginBottom: '30px',
        maxWidth: '33.33%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down(598)]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
    simpleProduct4row: {
        flex: '0 0 25%',
        marginTop: '8px',
        marginBottom: '30px',
        maxWidth: '25%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
    simpleProduct5row: {
        flex: '0 0 20%',
        maxWidth: '20%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('xs')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
    simpleProduct6row: {
        flex: '0 0 16.666666%',
        maxWidth: '16.666666%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        '& $productImage': {
            minHeight: 'auto',
            '& img': {
                width: '100%',
            },
        },
        [theme.breakpoints.down('xs')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
    fullWidthContainer: {
        width: '100%',
    },
});

const GraphqlSearchProducts = ({
    classes,
    fetchMoreResults,
    pageNumber,
    productNotAvaliable,
    products = [],
    productsPerRow,
    totalPages,
    searchTerm,
    featureFlags,
    totalProducts,
    track,
    presentationFamily,
    eventSubType,
    device,
    emitProductImpressionEvent,
    variables,
    priceRangeData,
    experiments,
}) => {
    const seed = useUIDSeed();
    const [loading, setLoading] = useState(false); // Load More button loading
    const ffIsLoadMoreOptimizationEnabled = useSelector(getFeatureFlag('is-load-more-optimization-enabled'));
    const ffIsLoadMoreCountShown = useSelector(getFeatureFlag('is-load-more-count-shown'));
    const ffScrollToTop = useSelector(getFeatureFlag('is-search-back-to-top-always-enabled'));
    const location = useLocation();

    useEffect(() => {
        track({
            nonInteraction: '1',
            eventCategory: 'Test Impression',
            eventAction: '<<pageType>>',
            eventLabel: 'Load More - Variant',
        });
    }, []);
    // loadMore button loading
    useEffect(() => {
        setLoading(false);
    }, [products.length]);

    const prevProductLength = usePrevious(products.length);

    const isLoadMoreAvailable = totalProducts > products?.length;
    const productsToLoadOnLoadMoreButton = ffIsLoadMoreOptimizationEnabled ? 36 : 12; // number of product when we click on load more button

    if (products) {
        trackProductListData({ products });

        useEffect(() => {
            emitProductImpressionEvent({
                productsPayload: {
                    products,
                    prevProductLength,
                    productPageSize: ffIsLoadMoreOptimizationEnabled ? 36 : 12,
                    productsPerRow,
                    mobileBreakpoint: 1024,
                    variables,
                    priceRangeData,
                },
                page: { type: eventSubType, searchTerm },
            });
        }, [JSON.stringify(products)]);
    }

    const categoryRow = () => {
        let cssPerRow = classes.simpleProduct3row;
        if (productsPerRow === 4 || productNotAvaliable) {
            cssPerRow = classes.simpleProduct4row;
        }
        if (productsPerRow === 5) {
            cssPerRow = classes.simpleProduct5row;
        }
        if (productsPerRow === 6) {
            cssPerRow = classes.simpleProduct6row;
        }
        return cssPerRow;
    };
    if (featureFlags['is-passport-eligible-banner']) {
        track({
            eventCategory: 'Test Impression', eventAction: 'passport eligible', eventLabel: 'search', nonInteraction: '1',
        });
    }

    // showing the product list conatiner on searchPage
    const ProductContainer = (
        <div className={classes.main}>
            {products?.map((product) => {
                const isFoodOrNotPersonalizable = (presentationFamily === 'food' || (!product?.isPersonalizable && product?.brand !== 'personalizationmall' && product?.brand !== 'thingsremembered'));
                const displayQuickview = featureFlags['is-search-quickview-modal-enabled'] && product?.productType !== 'CUSTOM_ASSORTMENT' && isFoodOrNotPersonalizable;
                return (
                    <Grid
                        className={categoryRow()}
                        key={seed(product)}
                        data-testid="search-components"
                    >
                        <GraphqlSimpleProduct
                            quickview={displayQuickview}
                            name={product?.name}
                            image={product?.image}
                            url={product?.seo?.url}
                            skuPriceRange={product?.skuPriceRange}
                            availability={product?.availability}
                            partNumber={product?.partNumber}
                            productNotAvaliable={product?.productNotAvaliable}
                            showSimpleProductRedesignAbTest={false}
                            productBrand={product.brandId}
                            productReviews={product?.reviews}
                            isCategoryPage
                            productSkus={product?.productSkus}
                            isPassportEligible={product?.isPassportEligible}
                            experiments={experiments}
                        />
                    </Grid>
                );
            })}
        </div>
    );

    return (
        <>
            {ProductContainer}
            <LoadMoreButton
                hasMore={(pageNumber <= totalPages) || ffScrollToTop}
                loading={loading}
                isLoadMoreAvailable={isLoadMoreAvailable}
                ssrDeviceType={device}
                path={location?.pathname}
                setLoading={setLoading}
                onLoadMore={fetchMoreResults}
                productToLoad={productsToLoadOnLoadMoreButton}
                totalProducts={products?.length}
                totalProductsToLoad={totalProducts}
                ffIsLoadMoreOptimizationEnabled={ffIsLoadMoreOptimizationEnabled}
                ffIsLoadMoreCountShown={ffIsLoadMoreCountShown}

                tracking={{
                    eventCategory: 'Search Page',
                    eventAction: 'Load More',
                    eventLabel: location?.pathname || '<<pageType>>',
                }}
            />
        </>

    );
};

GraphqlSearchProducts.propTypes = {
    classes: object.isRequired,
    presentationFamily: string.isRequired,
    products: arrayOf(shape({
        availability: shape({
            availabilityIndicator: string,
            deliveryDateType: string,
            deliveryMessage: string,
            displayEndDate: string,
            displayStartDate: string,
            earliestShipDate: string,
            latestShipDate: string,
            perishable: string,
            productDeliveryType: string,
            shipAlone: string,
            __typename: string,
        }),
        brand: string,
        image: shape({
            altText: string,
            name: string,
            snipe: string,
            __typename: string,
        }),
        name: string,
        partNumber: string,
        prices: arrayOf(shape({
            currency: string,
            type: string,
            value: number,
            __typename: string,
        })),
        seo: shape({
            url: string,
            __typename: string,
        }),
    })).isRequired,
    pageNumber: number,
    totalPages: number,
    productNotAvaliable: bool,
    fetchMoreResults: func,
    productsPerRow: number,
    searchTerm: string.isRequired,
    featureFlags: shape({
        'is-search-quickview-modal-enabled': bool,
    }),
    track: func,
    eventSubType: string,
    totalProducts: number.isRequired,
    device: string.isRequired,
    emitProductImpressionEvent: func,
    variables: object,
    priceRangeData: object,
    experiments: array,
};

GraphqlSearchProducts.defaultProps = {
    pageNumber: 1,
    productNotAvaliable: false,
    totalPages: '',
    eventSubType: '',
    fetchMoreResults: () => { },
    productsPerRow: 4,
    featureFlags: {
        'is-search-quickview-modal-enabled': false,
    },
    track: () => { },
    emitProductImpressionEvent: noop,
    variables: {},
    priceRangeData: {},
    experiments: [],
};

const mapStatesToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    presentationFamily: getPresentationFamily(state),
});

const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
    emitProductImpressionEvent: bindActionCreators(emitProductImpression, dispatch),
});

const enhance = compose(
    withStyles(styles),
    connect(mapStatesToProps, mapDispatchToProps),
);

export default enhance(GraphqlSearchProducts);
